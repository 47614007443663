import { graphql } from 'gatsby';

export function altMapImages(images, altMap) {
  return images.map((img) => ({
    name: img.node.name,
    alt: altMap[img.node.name],
    thumbnail: img.node.childImageSharp.thumbnail.src,
    original: img.node.childImageSharp.original.src
  }));
}

export const imageSetupFragment = graphql`
  fragment imageSetupFragment on ImageSharp {
    ... on ImageSharp {
      thumbnail: resize(width: 320, height: 320) {
        src
      }
      original: resize(width: 640, height: 640) {
        src
      }
    }
  }
`