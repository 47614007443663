import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

export default function ImageGrid(props) {

  const images = props.images;

  return (
    <div className="grid grid-cols-3 gap-2 sm:gap-2 md:gap-3 lg:gap-4 lg:grid-cols-4">
        <Gallery options={{
          closeEl: true,
          captionEl: false,
          fullscreenEl: false,
          zoomEl: false,
          shareEl: false,
          counterEl: true,
          arrowEl: true,
          preloaderEl: true
        }}>
        {images.map((img) => {
          return (<Item
              key={img.thumbnail}
              thumbnail={img.thumbnail}
              original={img.original}
              width="640"
              height="640">
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={img.thumbnail} alt={img.alt} width="320" height="320" />
              )}
            </Item>)
        })}
      </Gallery>
    </div>
  )
}
